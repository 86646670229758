// Scroller 80% på horisontal scroll til højre og sætter knappen disabled når man rammer bunden
const horizontalScrollButtonNext = (nextButtons: NodeListOf<Element>): void => {
    if (nextButtons) {
      nextButtons.forEach((nextButton) => {
        const topElem = nextButton?.parentElement?.parentElement?.parentElement?.parentElement;
        if (topElem) {
          const scrollElements = topElem.querySelectorAll('.horizontal-scroll__scrollable-content');
          const switch__segments = topElem.querySelectorAll('.switch__segment');
          scrollElements.forEach((scrollElement) => { 
            if (scrollElement) {
              nextButton.addEventListener('click', () => {
                // Scroller 80% af den synlige bredde på horizontal scroll til højre
                const scrollAmount = 0.8 * scrollElement.clientWidth;
                scrollElement.scrollLeft += scrollAmount;
              });
              // Gør knappen aktiv hvis scroll har ramt bunden
              scrollElement.addEventListener('scroll', () => {
                if ((scrollElement.scrollLeft + scrollElement.clientWidth) >= (scrollElement.scrollWidth * 0.98)) {
                  disableButton(nextButton);
                } else if (scrollElement.scrollLeft + scrollElement.clientWidth < scrollElement.scrollWidth) {
                  activateButton(nextButton);
                }
              });
              // Reset af knap ved flere tabs af horizontal scroll
              if (switch__segments) {
                switch__segments.forEach(switch__segment => {
                  switch__segment.addEventListener('click', () => {
                    activateButton(nextButton);
                    scrollElement.scrollLeft = 0;
                  })
                });
              }
            }
          });
        }
      });
    }
  };
  
  // Scroller 80% på horisontal scroll til venstre og sætter knappen disabled når man rammer toppen
  const horizontalScrollButtonPrevious = (previousButtons: NodeListOf<Element>): void => {
    if (previousButtons) {
      previousButtons.forEach((previousButton) => {
        const topElem = previousButton?.parentElement?.parentElement?.parentElement?.parentElement;
        if (topElem) {
          const scrollElements = topElem.querySelectorAll('.horizontal-scroll__scrollable-content');
          const switch__segments = topElem.querySelectorAll('.switch__segment');
          scrollElements.forEach((scrollElement) => { 
            if (scrollElement) {
              previousButton.addEventListener('click', () => {
                // Scroller 80% af den synlige bredde på horizontal scroll til venstre
                const scrollAmount = 0.8 * scrollElement.clientWidth;
                scrollElement.scrollLeft -= scrollAmount;
              });
              // Gør knappen aktiv hvis scroll har ramt toppen
              scrollElement.addEventListener('scroll', () => {
                if (scrollElement.scrollLeft <= 0) {
                  disableButton(previousButton);
                } else if (scrollElement.scrollLeft > 0) {
                  activateButton(previousButton);
                }
              });
            }
          // Reset af knap ved flere tabs af horizontal scroll
              if (switch__segments) {
                switch__segments.forEach(switch__segment => {
                  switch__segment.addEventListener('click', () => {
                    disableButton(previousButton);
                  });
                });
              }
          });
        }
      });
    }
  };

  function activateButton(button) {
    button.classList.remove('visually-disabled');
    button.setAttribute('aria-disabled', 'false');
  }
  
  function disableButton(button) {
    button.classList.add('visually-disabled');
    button.setAttribute('aria-disabled', 'true');
  }

  export {
    horizontalScrollButtonNext,
    horizontalScrollButtonPrevious,
  };