import { setContentToggle } from '../components/content-toggle';
import { setHorizontalScroll, setScrollbarWidthVariable } from '../components/helpers';
import { setCopyToClipboard } from '../components/nav';
import ready from '../components/ready';
import { setTabs } from '../components/tabs';
import { setTruncate } from '../components/truncator';
import setAffiliationExpand from '../components/affiliate-expand';
import { horizontalScrollButtonNext, horizontalScrollButtonPrevious } from '../components/horizontal-scroll-btn';

let windowInnerWidth = 0;
ready(() => {
  const elementButtons = document.querySelectorAll('.profile-header__secondary-affiliations__overflow-toggle');
  setAffiliationExpand(elementButtons);

  const tabbedContent = document.querySelectorAll('.tabbed-content');
  const tabbedObjects: ITabbedObject[] = [];
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs(tabbedObjects);

  const ctc = document.querySelectorAll('.copy-to-clipboard');
  ctc.forEach((element) => setCopyToClipboard(element as HTMLElement, document.querySelector('html')?.getAttribute('lang') === 'en' ? 'Copied!' : 'Kopieret!'));

  const toggles = document.querySelectorAll('.profile-header .content-toggle__content');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  // Hortizontal scroll buttons
  const scrollButtonsNext = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-next');
  const scrollButtonsPrevious = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-previous');
  horizontalScrollButtonNext(scrollButtonsNext);
  horizontalScrollButtonPrevious(scrollButtonsPrevious);

  const horizontalScroll = document.querySelectorAll('.horizontal-scroll');
  horizontalScroll.forEach((hs) => {
    setHorizontalScroll(hs as HTMLElement);
  });

  const truncators = document.querySelectorAll('.truncator');
  truncators.forEach((truncator) => setTruncate(truncator as HTMLElement));

  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  setScrollbarWidthVariable();

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      setTabs(tabbedObjects);
    }
  });
});
