/* eslint-disable max-len */
/* eslint-env browser */
/* eslint-disable import/prefer-default-export */
const setContentToggle = (
  content: Element,
  onClick?: ((content: HTMLElement, button: HTMLButtonElement) => void) | undefined,
  beforeCallback?: ((content: HTMLElement, button: HTMLButtonElement) => void) | undefined,
  afterCallback?: ((content: HTMLElement, button: HTMLButtonElement) => void) | undefined,
): CleanUpPair[] => {
  const cleanUp: CleanUpPair[] = [];
  const setContentClose = (button: HTMLElement, contentClose: HTMLElement, focus: boolean): () => void => {
    if (focus) {
      contentClose.focus();
    }
    const click = () => {
      content.setAttribute('hidden', 'hidden');
      button.removeAttribute('hidden');
      button.setAttribute('aria-expanded', 'false');
      button.focus();
    };
    contentClose.addEventListener('click', click);
    return click;
  };

  const button = content?.parentElement?.querySelector('.content-toggle__toggle') as HTMLElement;
  const contentClose = content.querySelectorAll('.content-toggle__close');

  if (button) {
    const clickEvent = () => {
      const ariaExpanded = button.getAttribute('aria-expanded');
      // Til toolbar: hvis man klikker på en knap, der ikke er foldet ud
      // Så lukker vi alle åbne content-toggles
      if (typeof beforeCallback === 'function' && ariaExpanded === 'false') {
        beforeCallback(content as HTMLElement, button as HTMLButtonElement);
      }
      // Og så går vi i gang med folde-ud-logikken
      button.setAttribute('aria-expanded', ariaExpanded === 'false' ? 'true' : 'false');
      if (ariaExpanded === 'true') {
        content.setAttribute('hidden', 'hidden');
      } else {
        content.removeAttribute('hidden');
      }

      if (typeof onClick === 'function') {
        onClick(content as HTMLElement, button as HTMLButtonElement);
      }

      if (button.hasAttribute('aria-haspopup')) {
        button.setAttribute('hidden', 'hidden');
        contentClose.forEach((cc) => {
          setContentClose(button, cc as HTMLElement, true);
        });
      }

      // Det der sker efter folde-logikken
      if (typeof afterCallback === 'function') {
        afterCallback(content as HTMLElement, button as HTMLButtonElement);
      }
    };

    button.addEventListener('click', clickEvent);
    // Altid tilføj denne først!
    cleanUp.push({
      element: button,
      clickEvent,
    });

    contentClose.forEach((cc) => {
      const btnClick = setContentClose(button, cc as HTMLElement, false);
      cleanUp.push({
        element: cc,
        clickEvent: btnClick,
      });
    });
  }
  return cleanUp;
};

export {
  setContentToggle,
};
