import { focusableSelector, overflowsY } from './helpers';

const truncate = (
  expandElement: HTMLElement,
  contentElement: HTMLElement,
): void => {
  if (overflowsY(contentElement)) {
    expandElement.removeAttribute('hidden');
  } else {
    expandElement.parentElement?.classList.add('truncator--no-truncation');
  }
};

const setTruncate = (truncator: HTMLElement): void => {
  const buttonElement = truncator.querySelector('.truncator__expand');
  const contentElement = truncator.querySelector('.truncator__content');

  if (buttonElement && contentElement) {
    buttonElement.addEventListener('click', () => {
      buttonElement.removeAttribute('hidden');
      truncator.classList.add('truncator--no-truncation');
    });

    truncate(buttonElement as HTMLElement, contentElement as HTMLElement);

    window.addEventListener('resize', () => {
      buttonElement.setAttribute('hidden', 'hidden');
      truncator.classList.remove('truncator--no-truncation');
      truncate(buttonElement as HTMLElement, contentElement as HTMLElement);
    });

    const focusElements = contentElement.querySelectorAll(focusableSelector);
    if (focusElements) {
      focusElements.forEach((f) => {
        f.addEventListener('focus', () => {
          if (overflowsY(f as HTMLElement)) {
            buttonElement.removeAttribute('hidden');
            truncator.classList.add('truncator--no-truncation');
          }
        });
      });
    }
  }
};

export {
  truncate,
  setTruncate,
};
